export const FORMATTED_EXO = [
  {
    id: 1,
    name: "Adaptive One 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 28,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023376465&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one" title="Adaptative One" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative One</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 4.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 2,
    name: "Adaptive One 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 31,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023385081&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one-1" title="Adaptative one - next" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative one - next</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one-1",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: "Cette contrainte NEXT(suivant) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une seule fois par combinaison de tir. A son annonce, le tireur doit annuler le tir qu'il devait effectuer sur un \u00e9l\u00e9ment \u00e0 atteindre et il doit passer au prochain \u00e9l\u00e9ment \u00e0 traiter.",
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peu \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 3,
    name: "Adaptive One 0003",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 32,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023383620&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one-back" title="Adaptative one - back" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative one - back</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one-back",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 8.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: "La contrainte BACK (retour) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une fois par combinaison de tir. A son annonce, le tireur doit revenir en arri\u00e8re et tirer sur les formes qu'il avait traiter en amont.",
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peu \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image: null,
      perception: null,
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 4,
    name: "Adaptive One 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 39,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1_7RptmoBVoVx8JnrFz5BcmebaWVdvysM",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023380044&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one-ancre-1" title="Adaptative one - avec contraintes" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative one - avec contraintes</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one-ancre-1",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 9.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 9.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: "Cette contrainte NEXT(suivant) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une seule fois par combinaison de tir. A son annonce, le tireur doit annuler le tir qu'il devait effectuer sur un \u00e9l\u00e9ment \u00e0 atteindre et il doit passer au prochain \u00e9l\u00e9ment \u00e0 traiter.",
      back: "La contrainte BACK (retour) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une fois par combinaison de tir. A son annonce, le tireur doit revenir en arri\u00e8re et tirer sur les formes qu'il avait traiter en amont.",
      abc123:
        "La contrainte ONEALFA impose au tireur de traiter les \u00e9l\u00e9ments A,B,C, 1,2,3 situ\u00e9s aux extr\u00e9mit\u00e9s de la cible. Ces \u00e9l\u00e9ments peuvent s'additionner \u00e0 une combinaison de tir. Il faut les annoncer en d\u00e9but d'exercice.",
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 5,
    name: "Adaptive One 0005",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 42,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1VUhcz9t-soZWKmDpmOqQa07QsbKOh98i/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023378430&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one-ancre" title="Adaptative one - ancre" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative one - ancre</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one-ancre",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 5.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor:
        "Avant de commencer la s\u00e9ance de tir, le tireur doit choisir un \u00e9l\u00e9ment parmis tout ceux qui sont pr\u00e9sent sur la cible. Ce symbole sera sont ancre. Lorsque la contrainte ANCRE est annonc\u00e9e, le tireur doit suspendre la combinaison de tir et viser son ancre. Il reprend ensuite, le reste de la combinaison de tir qu'il \u00e9tait entrain d'effecuter. Dans l'exemple ci-dessus, l'ancre est annonc\u00e9 apr\u00e8s le deuxi\u00e8me tir. Elle est utilisable une fois par combinaison de tir.",
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 6,
    name: "Adaptive One 0013",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 30,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023376465&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one" title="Adaptative One" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative One</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 3.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 6.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position:
        "Cette contrainte de tir va obliger le tireur \u00e0 r\u00e9aliser des positions adapt\u00e9es (debout, un genou \u00e0 terre, allong\u00e9, etc.",
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      adapted_position1:
        "POSITION ADAPTEE\n\nDans un environnement urbain ou en campagne, le tireur doit s'adapter aux diff\u00e9rentes pour faire face aux menaces.\nIl doit alors r\u00e9aliser des gestuelles de tir en prenullt en compte les \u00e9l\u00e9ments qui composent cet environnement. Dans cet exercice, le tireur devra r\u00e9aliser une posture sp\u00e9cifique (tir allong\u00e9, \u00e0 genoux ou debout) en fonction des tirs.",
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 7,
    name: "Adaptive One 0201",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 33,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023376465&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one" title="Adaptative One" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative One</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 2.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 8,
    name: "Adaptive One 0202",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 36,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1Ew-3DKlH3gjJC-rel_q_4InVtF_1m2Kd",
      target:
        "https://drive.google.com/open?id=1h94tE07nPu66gfNu41brPGb58QzonQz4",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023376465&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one" title="Adaptative One" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative One</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 1.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: "Cette contrainte NEXT(suivant) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une seule fois par combinaison de tir. A son annonce, le tireur doit annuler le tir qu'il devait effectuer sur un \u00e9l\u00e9ment \u00e0 atteindre et il doit passer au prochain \u00e9l\u00e9ment \u00e0 traiter.",
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-one-50-cibles",
    target: {
      name: "Adaptive One",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 9,
    name: "Adaptive Speed 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 19,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1-NtevMevyO06twClJCXkXQhtP-17B7LR",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023389932&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-speed" title="Adaptative Speed" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative Speed</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-speed",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: null,
      magazine1PA: 4.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 10,
    name: "Adaptive Speed 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 31,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1-NtevMevyO06twClJCXkXQhtP-17B7LR",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023397954&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-speed-next" title="Adaptative Speed - next" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative Speed - next</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-speed-next",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 7.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: "Cette contrainte NEXT(suivant) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une seule fois par combinaison de tir. A son annonce, le tireur doit annuler le tir qu'il devait effectuer sur un \u00e9l\u00e9ment \u00e0 atteindre et il doit passer au prochain \u00e9l\u00e9ment \u00e0 traiter.",
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 11,
    name: "Adaptive Speed 0003",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 21,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1-NtevMevyO06twClJCXkXQhtP-17B7LR",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023394387&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-one-back-1" title="Adaptative one - back" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative one - back</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-one-back-1",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 8.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: "La contrainte BACK (retour) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une fois par combinaison de tir. A son annonce, le tireur doit revenir en arri\u00e8re et tirer sur les formes qu'il avait traiter en amont.",
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image: null,
      perception: null,
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 12,
    name: "Adaptive Speed 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 39,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1j1oIzh9mGQRGGBfW8QWI78yjbkQ0ez6R",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023392314&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-speed-avec-contraines" title="Adaptative Speed - avec contraines" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative Speed - avec contraines</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-speed-avec-contraines",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 7.0,
      magazine1FA: 0.0,
      magazine1PA: 9.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 9.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: "Cette contrainte NEXT(suivant) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une seule fois par combinaison de tir. A son annonce, le tireur doit annuler le tir qu'il devait effectuer sur un \u00e9l\u00e9ment \u00e0 atteindre et il doit passer au prochain \u00e9l\u00e9ment \u00e0 traiter.",
      back: "La contrainte BACK (retour) est annonc\u00e9e durant la combinaison de tir pour stimuler le tireur. Elle est utilisable une fois par combinaison de tir. A son annonce, le tireur doit revenir en arri\u00e8re et tirer sur les formes qu'il avait traiter en amont.",
      abc123:
        "La contrainte ONEALFA impose au tireur de traiter les \u00e9l\u00e9ments A,B,C, 1,2,3 situ\u00e9s aux extr\u00e9mit\u00e9s de la cible. Ces \u00e9l\u00e9ments peuvent s'additionner \u00e0 une combinaison de tir. Il faut les annoncer en d\u00e9but d'exercice.",
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 13,
    name: "Adaptive Speed 0005",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 28,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1-NtevMevyO06twClJCXkXQhtP-17B7LR",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023391072&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-speed-ancre" title="Adaptative Speed - Ancre" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative Speed - Ancre</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-speed-ancre",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 5.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor:
        "Avant de commencer la s\u00e9ance de tir, le tireur doit choisir un \u00e9l\u00e9ment parmis tout ceux qui sont pr\u00e9sent sur la cible. Ce symbole sera sont ancre. Lorsque la contrainte ANCRE est annonc\u00e9e, le tireur doit suspendre la combinaison de tir et viser son ancre. Il reprend ensuite, le reste de la combinaison de tir qu'il \u00e9tait entrain d'effecuter. Dans l'exemple ci-dessus, l'ancre est annonc\u00e9 apr\u00e8s le deuxi\u00e8me tir. Elle est utilisable une fois par combinaison de tir.",
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 14,
    name: "Adaptive Speed 0006",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 20,
    },
    images: {
      drill:
        "https://drive.google.com/open?id=1-NtevMevyO06twClJCXkXQhtP-17B7LR",
      target:
        "https://drive.google.com/open?id=1nQ4CVkByrHBjC72wM0B9UMnZ5cT7DKC-",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1023389932&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/adaptative-speed" title="Adaptative Speed" target="_blank" style="color: #cccccc; text-decoration: none;">Adaptative Speed</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/adaptative-speed",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 4.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation:
        "La sommation doit \u00eatre annonc\u00e9e par un sifflet ou un autre signal avant, pendant ou apr\u00e8s le tir, pour correspondre \u00e0 un maximum de situation. Le signal peut \u00eatre un coup de sifflet, un timer, ou au traitement d'une forme sp\u00e9cifique (forme ou couleur). Par exemple, sur les formes triangles, annoncer la sommation.",
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/adaptative-speed-50-cibles",
    target: {
      name: "Adaptive Speed",
      count: 1,
    },
    objective:
      'R\u00e9aliser une combinaison de tir en utilisant les formes ou les couleurs.\n\nUne personne annonce au tireur une combinaison de tir \u00e0 traiter.\nCette combinaison est compos\u00e9e d\'un sens de traitement et de 4 \u00e9l\u00e9ments \u00e0 atteindre.\nPremi\u00e8rement, le bin\u00f4me donnera un sens de traitement. Par exemple : "du haut vers le bas" ou "du bas vers le haut" (par ligne), ou "de la gauche vers la droite" ou "de la droite vers la gauche" (par colonne).\nDeuxi\u00e8mement, le bin\u00f4me annoncera un \u00e9l\u00e9ment \u00e0 atteindre. Cet \u00e9l\u00e9ment peut \u00eatre une forme g\u00e9om\u00e9trique ou une couleur. Par exemple : "rouge", ou "rond", ou "triangle", etc.',
    direction: "Balayage par ligne et par colonne",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 15,
    name: "Consistency One 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 20,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1sN4fux0vTY9RvdHEv6dD1Jw3lm8M3BCf/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 1.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 1.0,
      withoutConstraints: 1.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective: "Sur la ligne 1, tirer 1 munition par \u00e9l\u00e9ment.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 16,
    name: "Consistency One 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 22,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1k_nGf1tWLcUdxOIev5Xq11Mzr2PZrQxC/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective: "Sur la ligne 1, tirer 3 munitions par \u00e9l\u00e9ment",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 17,
    name: "Consistency One 0003",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 20,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/12KZDVt-z_Fd2722duUlty2aRJQQYbEna/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 10.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 10.0,
      withoutConstraints: 10.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "De la ligne 2 \u00e0 la ligne 6, tirer 1 fois sur les \u00e9l\u00e9ments 1 et 2.\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C1 04, C1 05, C1 06.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 18,
    name: "Consistency One 0004",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 24,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1FTlj_wvLNvuQt-W8XX1wxa17680lqYhV/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 10.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 10.0,
      withoutConstraints: 10.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "De la ligne 2 \u00e0 la ligne 6, tirer 1 fois sur les \u00e9l\u00e9ments 3 et 4\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C1 03, C1 05, C1 06.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Compress ready",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 19,
    name: "Consistency One 0005",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 30,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/17dwtMn6tV4YCyzlNNpMqUGWWMWlL6bPX/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 10.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 10.0,
      withoutConstraints: 10.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "De la ligne 2 \u00e0 la ligne 6, tirer 1 fois sur les \u00e9l\u00e9ments 5 et 6\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C1 03, C1 04, C1 06.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Holster ready",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 20,
    name: "Consistency One 0006",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 30,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1zDf5qjjNtHHL5cyXLnaiIjNxg_AUNiVW/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 10.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 10.0,
      withoutConstraints: 10.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "De la ligne 2 \u00e0 la ligne 6, tirer 1 fois sur les \u00e9l\u00e9ments 7 et 8\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C1 03, C1 04, C1 05.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 21,
    name: "Consistency One 0007",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 21,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1Nr8NooxoH5uBcroJmpwLvsz608jyZpdY/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 1.0,
      magazine2FA: 0.0,
      magazine2PA: 9.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 10.0,
      withoutConstraints: 10.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "Sur la ligne 1, tirer 2 munitions par \u00e9l\u00e9ment. Op\u00e9ration \u00e0 r\u00e9p\u00e9ter plusieurs fois.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 22,
    name: "Consistency One 0008",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 22,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1sMylcuGyPeiEyuU7baDKOOqIIJzv-9-l/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "Sur la ligne 1, tirer 3 munitions par \u00e9l\u00e9ment. Op\u00e9ration \u00e0 r\u00e9p\u00e9ter plusieurs fois.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 23,
    name: "Consistency One 0009",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 27,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/166sQ-swcWuJxrkTt26VH5JtnjaVajZ1q/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 1.0,
      magazine1PA: 1.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 2.0,
      withoutConstraints: 2.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme:
        "Le rythme impos\u00e9 oblige le tireur \u00e0 sortir de sa zone de confort. Il doit respecter la cadence au risque de montrer ses limites. Le rythme peut \u00eatre repr\u00e9sent\u00e9 soit par un m\u00e9tronome, un bip, un sifflet, par un bin\u00f4me ou par lui m\u00eame.",
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "Sur la ligne 1, tirer 1 munitions sur un \u00e9l\u00e9ment. Effectuez une transition d'arme et tirer une munition dans le m\u00eame \u00e9l\u00e9ment.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img:
        "https://drive.google.com/file/d/1IEHe4qygLak_e-0ox_qpMz8bSGmqSG19/view?usp=sharing",
      rythme:
        "RYTHME IMPOSE\n\nLe rythme impos\u00e9 oblige le tireur \u00e0 suivre la cadence de tir et donc de sortir de sa zone de confort. L'int\u00e9r\u00eat est de voir rapidement les limites de la ma\u00eetrise technique des tireurs. Le rythme peut \u00eatre donn\u00e9 par une bande audio, disponible sur notre site internet, par un bipper ou timer, par un sifflet ou une lampe tactique \u00e0 main.",
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 24,
    name: "Consistency One 0010",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 27,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19GO_hYAkXxmzTCBz66B25d7ORiWxI6P9/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 1.0,
      magazine1PA: 4.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 5.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "Sur la ligne 1, tirer 4 munitions sur un \u00e9l\u00e9ment. Effectuez une transition d'arme et tirer une munition dans le m\u00eame \u00e9l\u00e9ment.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 25,
    name: "Consistency One 0011",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 31,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1hyZ9WJCmdvzgPpYjpK8TmIvHTvwHlg2V/view?usp=sharing",
      target:
        "https://drive.google.com/open?id=1_C8CA7-7NGxlzDyg9sPlwpa0tKPjDRqw",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 8.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 8.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme:
        "Le rythme impos\u00e9 oblige le tireur \u00e0 sortir de sa zone de confort. Il doit respecter la cadence au risque de montrer ses limites. Le rythme peut \u00eatre repr\u00e9sent\u00e9 soit par un m\u00e9tronome, un bip, un sifflet, par un bin\u00f4me ou par lui m\u00eame.",
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/consistency-one-50-cibles",
    target: {
      name: "Consistency One",
      count: 1,
    },
    objective:
      "Sur la ligne 2, tirer une munition de l'\u00e9l\u00e9ment 1 au 8.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img:
        "https://drive.google.com/file/d/1IEHe4qygLak_e-0ox_qpMz8bSGmqSG19/view?usp=sharing",
      rythme:
        "RYTHME IMPOSE\n\nLe rythme impos\u00e9 oblige le tireur \u00e0 suivre la cadence de tir et donc de sortir de sa zone de confort. L'int\u00e9r\u00eat est de voir rapidement les limites de la ma\u00eetrise technique des tireurs. Le rythme peut \u00eatre donn\u00e9 par une bande audio, disponible sur notre site internet, par un bipper ou timer, par un sifflet ou une lampe tactique \u00e0 main.",
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 26,
    name: "Consistency Two 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 20,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1sb0zN8ryRf4pB8vpyj5zj-X3TViN5jJF/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective: "Sur la ligne 1, tirer 1 munition par \u00e9l\u00e9ment.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 27,
    name: "Consistency Two 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 21,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/17NvCi1l7nq-siecp9Hga2wEqGQV4avWG/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 6.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 6.0,
      withoutConstraints: 6.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme:
        "Le rythme impos\u00e9 oblige le tireur \u00e0 sortir de sa zone de confort. Il doit respecter la cadence au risque de montrer ses limites. Le rythme peut \u00eatre repr\u00e9sent\u00e9 soit par un m\u00e9tronome, un bip, un sifflet, par un bin\u00f4me ou par lui m\u00eame.",
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective:
      "Sur la ligne 1, tirer rapidement 2 munitions par \u00e9l\u00e9ment.",
    direction: "De la droite vers la gauche.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img:
        "https://drive.google.com/file/d/1IEHe4qygLak_e-0ox_qpMz8bSGmqSG19/view?usp=sharing",
      rythme:
        "RYTHME IMPOSE\n\nLe rythme impos\u00e9 oblige le tireur \u00e0 suivre la cadence de tir et donc de sortir de sa zone de confort. L'int\u00e9r\u00eat est de voir rapidement les limites de la ma\u00eetrise technique des tireurs. Le rythme peut \u00eatre donn\u00e9 par une bande audio, disponible sur notre site internet, par un bipper ou timer, par un sifflet ou une lampe tactique \u00e0 main.",
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 28,
    name: "Consistency Two 0003",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 31,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1rmziQmgcAAn-R-lj4-ZGlcls95nK-P_X/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 9.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 9.0,
      withoutConstraints: 9.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective: "Sur la ligne 1, tirer 3 munitions par \u00e9l\u00e9ment.",
    direction: "De la gauche vers la droite.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 29,
    name: "Consistency Two 0004",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 21,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1cyfw5W1F4LAjUg_zNWovWdAohg6rrQiQ/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective:
      "Sur la premi\u00e8re colonne, tirer une munition par cercle.\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C2 05, C1 06.",
    direction: "Du haut vers le bas.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 30,
    name: "Consistency Two 0005",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 24,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1E0d1LagHyH7DWd_c1K1koIiRCw2CzS0s/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective:
      "Sur la deuxi\u00e8me colonne, tirer une munition par cercle.\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C2 04, C1 06.",
    direction: "Du haut vers le bas.",
    weapon: {
      position: "Compress ready",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 31,
    name: "Consistency Two 0006",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 30,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1x_1o-jFbUQ8B1U41kCbYIEYBgiWB8B2q/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/18QoE-wplyJf-Z9r7THMF80PktOuRxJTx/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-two-par-50",
    target: {
      name: "Consistency Two",
      count: 1,
    },
    objective:
      "Sur la troisi\u00e8me colonne, tirer une munition par cercle.\nPour \u00eatre efficace, cet exercice est \u00e0 combiner avec les exercices C2 04, C1 05.",
    direction: "Du haut vers le bas.",
    weapon: {
      position: "Holster ready",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 32,
    name: "Consistency Three 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 35,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1h0zGhVV7ShjWkngfZuEGltlrSbjqCZnZ/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/168nKEg8negpvHkGXzoAq54woNX6Y4v97/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 7.0,
      magazine1PA: 0.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 7.0,
      withoutConstraints: 7.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-three-par-50",
    target: {
      name: "Consistency Three",
      count: 1,
    },
    objective: "Tirer une munition par cercle",
    direction: "Libre",
    weapon: {
      position: "Compress ready",
      starting: "Fusil",
      transition: "Non",
      type: "FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 33,
    name: "Consistency Three 0002",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 61,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/16UQNOXX7vj-qVzpOA5vRLpoYgdDgiaAE/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/168nKEg8negpvHkGXzoAq54woNX6Y4v97/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 15.0,
      magazine1FA: 8.0,
      magazine1PA: 0.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 8.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-three-par-50",
    target: {
      name: "Consistency Three",
      count: 1,
    },
    objective:
      "Tirer deux munitions rapidement sur les cercles A, F, B puis G.",
    direction: "Pr\u00e9cis\u00e9 dans l'objectif de tir.",
    weapon: {
      position: "Compress ready",
      starting: "Fusil",
      transition: "Non",
      type: "FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 34,
    name: "Consistency Three 0003",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 30,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1gIDxJQMAoqD_xSAjVMO9-3xv_V-zSd8D/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/168nKEg8negpvHkGXzoAq54woNX6Y4v97/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 5.0,
      magazine1PA: 0.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 5.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-three-par-50",
    target: {
      name: "Consistency Three",
      count: 1,
    },
    objective:
      "Tirer deux munitions dans le cercle F, une munition dans le cercle D, puis deux munitions dans le cercle B.",
    direction: "Pr\u00e9cis\u00e9 dans l'objectif de tir.",
    weapon: {
      position: "Position contact",
      starting: "Fusil",
      transition: "Non",
      type: "FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 35,
    name: "Consistency Three 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 86,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1AKjj-XjLLNw3ZVGF5_iSTlwg5k734kaw/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/168nKEg8negpvHkGXzoAq54woNX6Y4v97/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 15.0,
      magazine1FA: 4.0,
      magazine1PA: 0.0,
      magazine2FA: 4.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Oui",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 8.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-three-par-50",
    target: {
      name: "Consistency Three",
      count: 1,
    },
    objective:
      "Tirer 4 munitions lentement dans le cercle D et 4 munitions rapidement dans le cercle F.",
    direction: "Pr\u00e9cis\u00e9 dans l'objectif de tir.",
    weapon: {
      position: "Holster",
      starting: "Fusil",
      transition: "Non",
      type: "FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 36,
    name: "Consistency Three 0005",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 41,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1XR5QtoKDAJuGiXzCNoxYT7OUKKXKEd7c/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/168nKEg8negpvHkGXzoAq54woNX6Y4v97/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 7.0,
      magazine1FA: 4.0,
      magazine1PA: 4.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 8.0,
      withoutConstraints: 8.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence:
        "La cadence de tir \u00e9lev\u00e9 dans cette exercice oblige le tireur \u00e0 avoir un bon maintien de l'arme. Ce maintien est primordial pour atteindre son objectif durant une succession de coups de feu.",
      global_position:
        "Attention particuli\u00e8re \u00e0 la position de tir durant cet exercice. Le drill doit se faire consciement et sans notion de temps pour r\u00e9aliser un ancrage profond de la gestuelle.",
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/cible-de-tir-consistency-three-par-50",
    target: {
      name: "Consistency Three",
      count: 1,
    },
    objective:
      "Tirer 1 munition dans le cercle F, 1 munition dans le cercle D, 1 munition dans le cercle A, 1 munition dans le cercle D, 1 munition dans le cercle B, 1 munition dans le cercle D, 1 munition dans cercle G et 1 munition dans le cercle D.",
    direction: "Pr\u00e9cis\u00e9 dans l'objectif de tir.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img:
        "https://drive.google.com/file/d/1NIsypS96LviM4CyL72MgU9qMHsbcrPL5/view?usp=sharing",
      global_gestu:
        "GESTUELLE GLOBALE\n\nR\u00e9aliser la bonne gestuelle de tir durant chaque action de feu est primordial. Dans cet exercice, le tireur doit focaliser son attention sur ce qu'il est entrain de faire dans sa gestuelle. Pour qu'elle soit parfaitement r\u00e9ussi, il doit se rem\u00e9morer chaque \u00e9tape important du tir.",
      weapon_keep_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      weapon_keep:
        "MAINTIEN DE L'ARME\n\nC'est une composante fondamentale dans la r\u00e9ussite d'un tir. L'arme doit \u00eatre fermement tenur par les deux mains malgr\u00e9 une cadence de tir parfois \u00e9lev\u00e9e.",
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 37,
    name: "Silhouette One 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 9,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation:
        "Le tireur ne doit pas regarder la cible avant que le signal qui informe du d\u00e9but de la s\u00e9quence de tir ne commence. Il doit d\u00e9couvrir la combinaison apr\u00e8s le signal (sifflet, bip, etc.).",
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective: "Tirer 3 munitions dans la zone l\u00e9tale basse.",
    direction: "Du haut vers le bas.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 38,
    name: "Silhouette One 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 14,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharingg",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 2.0,
      magazine1PA: 0.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 2.0,
      withoutConstraints: 2.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective: "Tirer 2 munitions dans la zone l\u00e9tale basse.",
    direction: "Libre",
    weapon: {
      position: "Position contact",
      starting: "Fusil",
      transition: "Non",
      type: "FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 39,
    name: "Silhouette One 0003",
    module: {
      name: "Module expert\n\u2b50\u2b50\u2b50",
      difficulty: 127,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 1.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Oui",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation:
        "Le tireur ne doit pas regarder la cible avant que le signal qui informe du d\u00e9but de la s\u00e9quence de tir ne commence. Il doit d\u00e9couvrir la combinaison apr\u00e8s le signal (sifflet, bip, etc.).",
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot:
        "Cette contrainte de tir va obliger le tireur \u00e0 tirer durant son d\u00e9placement. La vitesse doit \u00eatre adapt\u00e9e en fonction de la difficult\u00e9.",
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective: "Tirer 3 munitions dans la zone l\u00e9tale basse.",
    direction: "Du haut vers le bas.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image:
        "https://drive.google.com/file/d/1XbrPTTX1IgzNQiRj45aOgrCGz52v9jTH/view?usp=sharing",
      coord_visu_motr:
        "COORDINATION VISUO-MOTRICE\n\nLa coordination oeil-main est une aptitude qui nous permet de r\u00e9aliser plusieurs t\u00e2ches au quotidien. Un balayage visuel pour se rep\u00e9rer dans l\u2019espace et nos mains pour ex\u00e9cuter la t\u00e2che. Ce travail proprioceptif est fondamental pour notre survie et pour cette raison, il est imp\u00e9ratif de le stimuler et de le renforcer.",
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 40,
    name: "Silhouette One 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 48,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 15.0,
      magazine1FA: 1.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility:
        "Baisser la visibilit\u00e9 du lieu pour entrainer le tireur \u00e0 tirer dans des situations d\u00e9grad\u00e9es.",
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective:
      "Tirer 2 munitions dans la zone l\u00e9tale basse et 1 munition dans la zone l\u00e9tale haute.",
    direction: "Du bas vers le haut.",
    weapon: {
      position: "Holster",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 41,
    name: "Silhouette One 0005",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 10,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 2.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 5.0,
      withoutConstraints: 5.0,
    },
    constraints: {
      anticipation:
        "Le tireur ne doit pas regarder la cible avant que le signal qui informe du d\u00e9but de la s\u00e9quence de tir ne commence. Il doit d\u00e9couvrir la combinaison apr\u00e8s le signal (sifflet, bip, etc.).",
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target:
        "Pour casser la routine visuelle, l'inclinaison de la cible forcera le tireur \u00e0 changer ses points de rep\u00e8re pour atteindre son objectif.",
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective: "Tirer 5 munitions rapidement dans la zone l\u00e9tale basse.",
    direction: "Libre",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 42,
    name: "Silhouette One 0006",
    module: {
      name: "Module expert\n\u2b50\u2b50\u2b50",
      difficulty: 122,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 2.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot:
        "Cette contrainte de tir va obliger le tireur \u00e0 tirer durant son d\u00e9placement. La vitesse doit \u00eatre adapt\u00e9e en fonction de la difficult\u00e9.",
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective: "Tirer 4 munitions rapidement dans la zone l\u00e9tale haute.",
    direction: "Libre",
    weapon: {
      position: "Holster",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image:
        "https://drive.google.com/file/d/1XbrPTTX1IgzNQiRj45aOgrCGz52v9jTH/view?usp=sharing",
      coord_visu_motr:
        "COORDINATION VISUO-MOTRICE\n\nLa coordination oeil-main est une aptitude qui nous permet de r\u00e9aliser plusieurs t\u00e2ches au quotidien. Un balayage visuel pour se rep\u00e9rer dans l\u2019espace et nos mains pour ex\u00e9cuter la t\u00e2che. Ce travail proprioceptif est fondamental pour notre survie et pour cette raison, il est imp\u00e9ratif de le stimuler et de le renforcer.",
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 43,
    name: "Silhouette One 0007",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 108,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/19hFdlvFHkiL19lsmBtOHkbDZ3hmCJiKC/view?usp=sharing",
    },
    audio: {
      embed:
        '<iframe width="100%" height="450" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1208635234&color=%23ff5500&auto_play=false&hide_related=false&show_comments=false&show_user=false&show_reposts=false&show_teaser=false"></iframe><div style="font-size: 10px; color: #cccccc;line-break: anywhere;word-break: normal;overflow: hidden;white-space: nowrap;text-overflow: ellipsis; font-family: Interstate,Lucida Grande,Lucida Sans Unicode,Lucida Sans,Garuda,Verdana,Tahoma,sans-serif;font-weight: 100;"><a href="https://soundcloud.com/creative-digital-target" title="cdtarget" target="_blank" style="color: #cccccc; text-decoration: none;">cdtarget</a> \u00b7 <a href="https://soundcloud.com/creative-digital-target/sets/silhouette-one" title="Silhouette one" target="_blank" style="color: #cccccc; text-decoration: none;">Silhouette one</a></div>',
      soundcloud:
        "https://soundcloud.com/creative-digital-target/sets/silhouette-one",
      assistant:
        "https://drive.google.com/file/d/1gQCNORBD3AIB2akfUumvyycdtd4OgjGE/view?usp=sharing",
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot:
        "Cette contrainte de tir va obliger le tireur \u00e0 tirer durant son d\u00e9placement. La vitesse doit \u00eatre adapt\u00e9e en fonction de la difficult\u00e9.",
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: "https://cdtar.com/products/silhouette-one-50-cibles",
    target: {
      name: "Silhouette One",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image:
        "https://drive.google.com/file/d/1XbrPTTX1IgzNQiRj45aOgrCGz52v9jTH/view?usp=sharing",
      coord_visu_motr:
        "COORDINATION VISUO-MOTRICE\n\nLa coordination oeil-main est une aptitude qui nous permet de r\u00e9aliser plusieurs t\u00e2ches au quotidien. Un balayage visuel pour se rep\u00e9rer dans l\u2019espace et nos mains pour ex\u00e9cuter la t\u00e2che. Ce travail proprioceptif est fondamental pour notre survie et pour cette raison, il est imp\u00e9ratif de le stimuler et de le renforcer.",
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 44,
    name: "Silhouette Twin alfa 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 9,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-alfa-50-cibles",
    target: {
      name: "Silhouette Twin alfa",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 45,
    name: "Silhouette Twin alfa 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 22,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharingg",
      target:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-alfa-50-cibles",
    target: {
      name: "Silhouette Twin alfa",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 46,
    name: "Silhouette Twin alfa 0003",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 39,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 1.0,
      magazine1PA: 1.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Oui",
      magazineChangesPA: "Non",
      withConstraints: 2.0,
      withoutConstraints: 2.0,
    },
    constraints: {
      anticipation:
        "Le tireur ne doit pas regarder la cible avant que le signal qui informe du d\u00e9but de la s\u00e9quence de tir ne commence. Il doit d\u00e9couvrir la combinaison apr\u00e8s le signal (sifflet, bip, etc.).",
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-alfa-50-cibles",
    target: {
      name: "Silhouette Twin alfa",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 47,
    name: "Silhouette Twin alfa 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 46,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1Sr4S1u_8NpNLLEoLL4bLLDo30P7Xje3D/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 2.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123:
        "La contrainte ONEALFA impose au tireur de traiter les \u00e9l\u00e9ments A,B,C, 1,2,3 situ\u00e9s aux extr\u00e9mit\u00e9s de la cible. Ces \u00e9l\u00e9ments peuvent s'additionner \u00e0 une combinaison de tir. Il faut les annoncer en d\u00e9but d'exercice.",
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility:
        "Baisser la visibilit\u00e9 du lieu pour entrainer le tireur \u00e0 tirer dans des situations d\u00e9grad\u00e9es.",
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target:
        "Pour casser la routine visuelle, l'inclinaison de la cible forcera le tireur \u00e0 changer ses points de rep\u00e8re pour atteindre son objectif.",
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-alfa-50-cibles",
    target: {
      name: "Silhouette Twin alfa",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 48,
    name: "Silhouette Twin bravo 0001",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 9,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-bravo-50-cibles",
    target: {
      name: "Silhouette Twin bravo",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 49,
    name: "Silhouette Twin bravo 0002",
    module: {
      name: "Module initial\n\u2b50",
      difficulty: 22,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharingg",
      target:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 1.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 3.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-bravo-50-cibles",
    target: {
      name: "Silhouette Twin bravo",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Position contact",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 50,
    name: "Silhouette Twin bravo 0003",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 39,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 1.0,
      magazine1PA: 1.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Oui",
      magazineChangesPA: "Non",
      withConstraints: 2.0,
      withoutConstraints: 2.0,
    },
    constraints: {
      anticipation:
        "Le tireur ne doit pas regarder la cible avant que le signal qui informe du d\u00e9but de la s\u00e9quence de tir ne commence. Il doit d\u00e9couvrir la combinaison apr\u00e8s le signal (sifflet, bip, etc.).",
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-bravo-50-cibles",
    target: {
      name: "Silhouette Twin bravo",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Arme de poing",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img:
        "https://drive.google.com/file/d/1T3se-vfvZP2BPNyfPwF2KZvcNa0HDjOZ/view?usp=sharing",
      switch_magazine:
        "CHANGEMENT DE CHARGEUR\n\nLe changement de chargeur d'une arme est la capacit\u00e9 d'un tireur \u00e0 effectuer la bonne gestuel dans une situation donn\u00e9e. Cela peut \u00eatre un changement de chargeur d'urgence ou un changement de chargeur tactique. La bonne gestuelle fera gagner de pr\u00e9cieuses secondes au tireur dans une situation de stress.",
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 51,
    name: "Silhouette Twin bravo 0004",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 46,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 10.0,
      magazine1FA: 2.0,
      magazine1PA: 3.0,
      magazine2FA: 0.0,
      magazine2PA: 0.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Non",
      withConstraints: 5.0,
      withoutConstraints: 3.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123:
        "La contrainte ONEALFA impose au tireur de traiter les \u00e9l\u00e9ments A,B,C, 1,2,3 situ\u00e9s aux extr\u00e9mit\u00e9s de la cible. Ces \u00e9l\u00e9ments peuvent s'additionner \u00e0 une combinaison de tir. Il faut les annoncer en d\u00e9but d'exercice.",
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility:
        "Baisser la visibilit\u00e9 du lieu pour entrainer le tireur \u00e0 tirer dans des situations d\u00e9grad\u00e9es.",
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target:
        "Pour casser la routine visuelle, l'inclinaison de la cible forcera le tireur \u00e0 changer ses points de rep\u00e8re pour atteindre son objectif.",
      mobility_shot: null,
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-bravo-50-cibles",
    target: {
      name: "Silhouette Twin bravo",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Holster",
      starting: "Fusil",
      transition: "Oui",
      type: "PA & FA",
    },
    skills: {
      ident_image:
        "https://drive.google.com/file/d/1zg8wr0WHXYkOMaK5TpjTFJVil2p3E5ZI/view?usp=sharing",
      ident:
        "IDENTIFICATION D'OBJECTIF \n\n\nFacult\u00e9 \u00e0 visualiser un objectif parmi des \u00e9l\u00e9ments. Avec cette gymnastique, le cerveau renforce son m\u00e9canisme d\u2019identification et multiplie sa rapidit\u00e9 de traitement des informations.",
      perception_image:
        "https://drive.google.com/file/d/16EtGjG0wzIZEdE9c0Bs6Q_OaU0CxneLI/view?usp=sharing",
      perception:
        "PERCEPTION DE L'ENVIRONNEMENT\n\nL\u2019ensemble des objets de notre monde sont bas\u00e9s sur des formes fortes. Le cercle, le carr\u00e9 et le triangle sont des formes de bases qui servent de point de d\u00e9part \u00e0 d\u2019autres formes complexes. La stimulation ludique de ces formes permet de consolider et d'accro\u00eetre notre rapidit\u00e9 de perception. Cette perception peut \u00eatre visuelle, auditive ou kinesth\u00e9sique.",
      spatia_image:
        "https://drive.google.com/file/d/1mZ1RxuKfDGJj3VPypEqXPqTqxB67zVa7/view?usp=sharing",
      spatia:
        "SPATIALISATION\n\nCapacit\u00e9 \u00e0 chercher \u00e9nergiquement une information de notre environnement en 3D et d'y conna\u00eetre sa place ou celle d'autres individus. Notre facult\u00e9 \u00e0 faire preuve d\u2019implantation nous permet de pr\u00e9visualiser et d'anticiper nos mouvements.\nS'entra\u00eener \u00e0 reconna\u00eetre des formes g\u00e9om\u00e9triques et des couleurs favorise le rendement de notre balayage visuel et stimule notre capacit\u00e9 \u00e0 spatialiser.",
      adapt_capacity_image:
        "https://drive.google.com/file/d/1Ys-g7l0TPSH4hnmrKP8ridH2CbYlRJyu/view?usp=sharing",
      adapt_capacity:
        "ADAPTATION\n\nAptitude \u00e0 \u00e9voluer dans une nouvelle configuration, en recueillant des informations et en les restituant rapidement.\nRenforcer cette comp\u00e9tence permet d\u2019anticiper rapidement des situations complexes et d\u2019avoir un temps d\u2019avance.",
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image:
        "https://drive.google.com/file/d/1QG4EprzorQjaU5tEFonNdBkMxbLG62qm/view?usp=sharing",
      short_memory:
        "MEMORISATION\n\nFaisant partie int\u00e9grante de notre m\u00e9moire sensorielle, la m\u00e9moire auditive \u00e0 court terme nous permet de traiter rapidement des informations sonores. Cette derni\u00e8re permet de retenir plus de donn\u00e9es que la m\u00e9moire visuelle \u00e0 court terme et plus longtemps.",
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image:
        "https://drive.google.com/file/d/12a8dwj_wdfaPReLX9-ugttU5a6vXUHz4/view?usp=sharing",
      share_attention:
        "ATTENTION PARTAGEE\n\nCapacit\u00e9 \u00e0 ex\u00e9cuter avec r\u00e9ussite plusieurs actions \u00e0 la fois, en focalisant aussi son attention sur d'autres stimulus.\nCela nous permet de pr\u00eater attention \u00e0 un facteur d\u00e9terminullt qui peut modifier une situation.",
      spatia_obj_image:
        "https://drive.google.com/file/d/1VEUGFVNDwZ9mJebYbeIGkoKIPnG_k1BZ/view?usp=sharing",
      spatia_obj:
        "SPATIALISATION DES OBJECTIFS\n\nCapacit\u00e9 \u00e0 visualiser un symbole ou un concept, un chemin \u00e0 prendre ou l'emplacement futur d'un objectif. Le corps est ainsi pr\u00e9par\u00e9 \u00e0 adapter sa gestuelle en cons\u00e9quence.",
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img:
        "https://drive.google.com/file/d/1vl9CGboFVUD60HXNnmSGebN7R-UfqZTI/view?usp=sharing",
      switch_arme:
        "TRANSITION D'ARME\n\nIl est primordial de ma\u00eetriser les transitions d'armes. Ces transitions permettent de gagner un temps pr\u00e9cieux face \u00e0 une menace lors d'un changement de chargeur d'urgence ou tactique. Le tireur doit r\u00e9aliser des tirs et exercer des transitions d'armes. A optimiser en fonction des combinaisons de tir.",
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: 52,
    name: "Silhouette Twin bravo 0005",
    module: {
      name: "Module avanc\u00e9\n\u2b50\u2b50",
      difficulty: 92,
    },
    images: {
      drill:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
      target:
        "https://drive.google.com/file/d/1IyuNQyHPJ45V0rLkrEeZnUnx_ZpGHWy0/view?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: 5.0,
      magazine1FA: 0.0,
      magazine1PA: 2.0,
      magazine2FA: 0.0,
      magazine2PA: 2.0,
      magazineChangesFA: "Non",
      magazineChangesPA: "Oui",
      withConstraints: 4.0,
      withoutConstraints: 4.0,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot:
        "Cette contrainte de tir va obliger le tireur \u00e0 tirer durant son d\u00e9placement. La vitesse doit \u00eatre adapt\u00e9e en fonction de la difficult\u00e9.",
      adapted_position: null,
      transition_arme:
        "Cette contrainte va forcer le tireur r\u00e9aliser une transition d'arme pour simuler un rechargement tactique ou d'urgence.",
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://cdtar.com/collections/toutes-la-collection-cdt/products/silhouette-twin-bravo-50-cibles",
    target: {
      name: "Silhouette Twin bravo",
      count: 1,
    },
    objective:
      "Traiter une combinaison de tir en utilisant les formes et les zones l\u00e9tales.",
    direction: "Sous ordre de l'instructeur.",
    weapon: {
      position: "Compress ready",
      starting: "Arme de poing",
      transition: "Non",
      type: "PA",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image:
        "https://drive.google.com/file/d/1XbrPTTX1IgzNQiRj45aOgrCGz52v9jTH/view?usp=sharing",
      coord_visu_motr:
        "COORDINATION VISUO-MOTRICE\n\nLa coordination oeil-main est une aptitude qui nous permet de r\u00e9aliser plusieurs t\u00e2ches au quotidien. Un balayage visuel pour se rep\u00e9rer dans l\u2019espace et nos mains pour ex\u00e9cuter la t\u00e2che. Ce travail proprioceptif est fondamental pour notre survie et pour cette raison, il est imp\u00e9ratif de le stimuler et de le renforcer.",
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: null,
    name: null,
    module: {
      name: null,
      difficulty: null,
    },
    images: {
      drill: null,
      target: null,
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation: null,
      shooter2: null,
      extension_2target: null,
      exit_pan: null,
      oral_validation_shoot: null,
      low_visibility: null,
      platre_nade: null,
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link: null,
    target: {
      name: null,
      count: null,
    },
    objective: null,
    direction: null,
    weapon: {
      position: null,
      starting: null,
      transition: null,
      type: null,
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
  {
    id: "Google drive",
    name: "https://drive.google.com/drive/folders/1RqEdAFbnQK2cCtTlNw0Cf4iUSaBbxdZH?usp=sharing",
    module: {
      name: "https://drive.google.com/drive/folders/1uqHdlnnvUp0l6fVpVMLQpi1IwSOpyHcU?usp=sharing",
      difficulty:
        "https://drive.google.com/drive/folders/1pdHXvZxAWpLptPb8cIece0wIseUxXVRE?usp=sharing",
    },
    images: {
      drill:
        "https://drive.google.com/drive/folders/1Rx_yIpDAhnKabTDnh2p0D6HoJe8HfPrU?usp=sharing",
      target:
        "https://drive.google.com/drive/folders/1E1BAE6ksRCcGid6O4Vmu-PQohvWu9JOy?usp=sharing",
    },
    audio: {
      embed: null,
      soundcloud: null,
      assistant: null,
    },
    ammunition: {
      distance: null,
      magazine1FA: null,
      magazine1PA: null,
      magazine2FA: null,
      magazine2PA: null,
      magazineChangesFA: null,
      magazineChangesPA: null,
      withConstraints: null,
      withoutConstraints: null,
    },
    constraints: {
      anticipation: null,
      non_pertinente_info: null,
      aller_retour: null,
      contre_ordre: null,
      next: null,
      back: null,
      abc123: null,
      anchor: null,
      sommation:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      shooter2:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      extension_2target:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      exit_pan:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      oral_validation_shoot:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      low_visibility:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      platre_nade:
        "https://drive.google.com/drive/folders/1FSiMsWYzJk0iXmUX7ru39exPqIXF5B-m?usp=sharing",
      laser_target: null,
      smoke: null,
      form_part: null,
      inclinaison_target: null,
      mobility_shot: null,
      adapted_position: null,
      transition_arme: null,
      rythme: null,
      cadence: null,
      global_position: null,
    },
    shop_link:
      "https://drive.google.com/drive/folders/1GC9YZCu7uR_a6Btc44VlT7USiyH5FwMe?usp=sharing",
    target: {
      name: "https://drive.google.com/drive/folders/1KzLkuigkbqye4eJkeyqwIlRd29FcMu-z?usp=sharing",
      count:
        "https://drive.google.com/drive/folders/1zLWlC8JRcFE6QBHv3VVPmBQkTJUc1CLH?usp=sharing",
    },
    objective:
      "https://drive.google.com/drive/folders/1RqEdAFbnQK2cCtTlNw0Cf4iUSaBbxdZH?usp=sharing",
    direction:
      "https://drive.google.com/drive/folders/1RqEdAFbnQK2cCtTlNw0Cf4iUSaBbxdZH?usp=sharing",
    weapon: {
      position: null,
      starting: null,
      transition:
        "https://drive.google.com/drive/folders/1KzLkuigkbqye4eJkeyqwIlRd29FcMu-z?usp=sharing",
      type: "https://drive.google.com/drive/folders/1qiDZcrt80aV0bUKkQsJgcIP8eRXuGVz_?usp=sharing",
    },
    skills: {
      ident_image: null,
      ident: null,
      perception_image: null,
      perception: null,
      spatia_image: null,
      spatia: null,
      adapt_capacity_image: null,
      adapt_capacity: null,
      inhib_capacity_image: null,
      ihib_capacity: null,
      short_memory_image: null,
      short_memory: null,
      coord_visu_motr_image: null,
      coord_visu_motr: null,
      share_attention_image: null,
      share_attention: null,
      spatia_obj_image: null,
      spatia_obj: null,
      global_gestu_img: null,
      global_gestu: null,
      weapon_keep_img: null,
      weapon_keep: null,
      rythme_img: null,
      rythme: null,
      switch_magazine_img: null,
      switch_magazine: null,
      adapted_position_img: null,
      adapted_position1: null,
      switch_arme_img: null,
      switch_arme: null,
      cardio_img: null,
      cardio: null,
    },
  },
];
